import React, {useEffect, useRef, useState} from 'react';

import './hero.scss'

import WhiteBMW from './../img/white-bmw.png'
import GreenBMW from './../img/green-bmw.png'
import PhoneIcon from "../img/icons/phone.svg";


const slides = [
  'sell-car',
  'buy-car'
]


const Hero = () => {

  const [activeSlide, setActiveSlide] = useState(0)

  let gradient = useRef()

  // animate gradient background
  window.addEventListener('mousemove', e => {
    if (gradient.current == null) return false
    const gradientCenter = calcGradPos(e.x, e.y)
    gradient.current.style.background = gradientStyle(gradientCenter)
    // TODO: add effect on mobiles
  })

  const gradientStyle = (center) => {
    return `radial-gradient(circle at ${center}, rgba(255,255,255,1) 5%, rgba(200, 200, 200,1) 40%, rgba(50,50,50,1) 100%)`
  }

  const calcGradPos = (x, y) => {
    const warpConst = 0.2
    const position = {
      x: 50 + ((((x / window.innerWidth) - 0.5) * 100).toFixed(2) * warpConst),
      y: 50 + ((((y / window.innerHeight) - 0.5) * 100).toFixed(2) * warpConst)
    }
    return `${position.x}% ${position.y}%`
  }

  // auto change slides
  useEffect(() => {
    const slideTime = 7000 // time for display each slide
    const timer = setTimeout(() => ChangeSlide(), slideTime)
    const ChangeSlide = () => {
      const nextSlide = activeSlide === slides.length - 1 ? 0 : activeSlide + 1
      setActiveSlide(nextSlide)
    }
    return () => clearTimeout(timer)
  }, [activeSlide])


  return (
    <>
      <div className={`section hero`} id={`hero`} data-active={slides[activeSlide]}>

        <div className="slides">
          <div className="slide" id={`sell-car`}>
            <div className="content">
              <div className="hero-floating">
                <div className="hero-box">
                  <div className="hero-box-inner">
                    <div className="text">
                      <h1>
                        <span className={`line1`}>Sprzedaj</span>
                        <span className={`line2`}>Samochód</span>
                        <span className={`line3`}>w 5 krokach</span>
                      </h1>
                    </div>
                    <a href="#sprzedaj" className={`hero-btn`}>
                      <button className="btn btn-light btn-round">
                        Przejdź do formularza
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <img src={WhiteBMW} alt="" className={`car-img`}/>
          </div>

          <div className="slide" id={`buy-car`}>
            <div className="content">
              <div className="hero-floating">
                <div className="hero-box">
                  <div className="hero-box-inner">
                    <div className="text">
                      <h1>
                        <span className={`line1`}>Szukasz</span>
                        <span className={`line2`}>Pewnego</span>
                        <span className={`line3`}>Samochodu?</span>
                      </h1>
                    </div>
                    <a href="#oferowane-samochody" className={`hero-btn`}>
                      <button className="btn btn-light btn-round">
                        Nasze samochody
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <img src={GreenBMW} alt="" className={`car-img`}/>
          </div>

        </div>

        <div className="hero-bg">
          <div className="gradient" style={{background: gradientStyle(`50% 50%`)}} ref={gradient}/>
          <div className="solid-color" id={`buy-car`}/>
          <div className="solid-color" id={`sell-car`}/>
        </div>
      </div>
    </>
  );
};

export default Hero;
