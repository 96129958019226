import React, {useState} from 'react';
import Box from "./box";

import Feature from "./feature-single";


import ChargerImg from "../img/gift_charger.png"
import MaxiecuImg from "../img/gift_maxiecu.png"
import FeaturesList from "./features-list";


const features = {
  profits: [
    {
      title: "Więcej wolnego czasu i spokoju",
      content: `Wyczyścić samochód, zrobić zdjęcia, opisać i wystawić na portalach – brzmi prosto, ale wymaga czasu i opłat za publikacje, czasem i poniesienia kosztów prowizji.. Później odbieranie telefonów z tymi samymi pytaniami, kolejne spotkania z kupcami, negocjowanie, albo co gorsza- martwienie się, że nikt nie jest zainteresowany naszym ogłoszeniem. MARI jest rozwiązaniem na te uciążliwości. Z nami sprzedaż samochodu przebiegnie bezproblemowo. `,
      slug: 'wiecej-czasu'
    },
    {
      title: 'Gotówkę już w ciągu 60 minut',
      content: "Jadąc na wycenę jesteśmy nastawni na kupno, więc jeśli zgodzisz się na sprzedaż – będziemy gotowi do wypłaty pieniędzy dla Ciebie. Bez problemu możemy umówić się na płatność gotówką lub przelewem.",
      slug: 'wplata'
    },
    {
      title: 'Bezpłatnie sprawdzimy stan Twojego samochodu',
      content: (<>
        <p>
          Nie pobieramy żadnych opłat za dojazd. Nie wyceniamy na podstawie automatycznych cenników czy ogólnikowych
          taryfikatorów.
        </p>
        <p>
          Mamy wiedzę i narzędzia diagnostyczne dzięki którym wraz z wyceną otrzymasz od nas rzetelny raport stanu
          pojazdu i informacje o ewentualnych usterkach. Nawet jeśli nie zdecydujesz się na sprzedaż, możemy doradzić
          jakie inwestycje mogą podnieść wartość samochodu.
        </p>
      </>),
      slug: 'wycena'
    },
    {
      title: 'Uczciwą wycenę',
      content: "Daleko nam od stereotypowego wizerunku Mirka handlarza :) Zarysowanie nie jest dla nas powodem do zbicia ceny o połowę. Nie ubarwiamy i nie naginamy faktów – po prostu mówimy szczerze jak jest. Większość późniejszych napraw wykonujemy samodzielnie, dzięki czemu nie zaniżamy ceny zakupu ze względu na koszty dla pośredników.",
      slug: 'uczciwa-wycena'
    },
    {
      title: 'Klarowną i korzystną umowę',
      content: "Podpisujemy klarowną umowę kupna-sprzedaży, więc masz pewność, że nie ma dla Ciebie żadnych ukrytych kosztów, prowizji, opłat komisowych itp. Możemy również zapewnić wsparcie przy formalnościach związanych ze zbyciem pojazdu. Jeśli będziesz chciał odzyskać zwrot za niewykorzystany okres składki OC – przekażemy gotowy dokument dla ubezpieczyciela. Otrzymasz od nasz niezbędne wskazówki co? kiedy? i jak?",
      slug: 'profit'
    },
    {
      title: 'Prezent do każdej transakcji',
      content: (<p>
        Od nas dostaniesz nie tylko korzystną cenę! Dzięki naszej współpracy z wiodącymi producentami w branży
        automotive – dla każdego sprzedającego mamy przygotowany oryginalny prezent. W zależności od wartości samochodu
        otrzymasz od nas np. przydatne urządzenie wielofunkcyjne zapewniające wygodę i bezpieczeństwo lub niezawodny <b>interfejs
        z oprogramowaniem diagnostycznym o wartości 499,00 zł!</b> Na pewno będą niezawodne w nowym samochodzie, który
        być może również kupisz od nas?
      </p>),
      slug: 'prezenty'
    }
  ],
  fundations: [
    {
      title: 'Kompleksowa oferta',
      content: `Jesteśmy zainteresowani zakupem pojazdu, niezależnie od jego wersji, stanu technicznego czy wizualnego. Kupujemy samochody osobowe, terenowe i dostawcze.
Nie odrzucamy zgłoszeń na pojazdy powypadkowe, skorodowane, zaniedbane i po innych przejściach. Nie odstrasza nas również brak aktualnej polisy OC czy ważnego przeglądu technicznego. Auto sprzedawane do naszego skupu nie musi być nawet na chodzie.
Nasza oferta jest zarówno dla osób prywatnych, firm (w tym skup flot), firm windykacyjnych czy leasingowych.`,
      slug: 'kompleksowa'
    },
    {
      title: 'Minimum formalności',
      content: 'Przygotujemy wszystkie dokumenty zakupu, pełnomocnictwa (w przypadku współwłaściciela nieobecnego przy sprzedaży) i te, które będą dla Ciebie niezbędne m.in. do zawiadomienia o zbyciu pojazdu, wyrejestrowania czy zgłoszenia do ubezpieczyciela. Wszystko zgodnie z obowiązującymi przepisami prawa, również w zakresie bezpieczeństwa danych osób uczestniczących w transakcji.',
      slug: 'minimum-formalnosci'
    },
    {
      title: 'Mobilność i terminowość',
      content: (<>
        <p>
          Chcesz żebyśmy przyjechali za godzinę? Będziemy starać się tak zrobić, ale musisz wiedzieć, że priorytetem
          jest dla nas jakość obsługi a nie ilość „klepniętych transakcji”. Nie staramy się więc prześcignąć innych
          kupujących gwarantujących natychmiastowy przyjazd. Ustalamy najszybszy możliwy termin – tak aby wpasować się w
          Twój grafik oraz mieć realny czas do przygotowania satysfakcjonującej oferty dla Ciebie.
        </p>
        <p>
          Dzięki temu, że mamy własną pomoc drogową - zapewniamy transport sprzedanego samochód natychmiast po
          transakcji.
        </p>
        <p>
          Naszą ofertę kierujemy nie tylko do Klientów z Trójmiasta (Gdańsk, Sopot, Gdynia), ale z całego województwa
          pomorskiego.
        </p>
        <p>
          Samochód jest w innej części Polski? Ok, skontaktuj się z nami – ustalimy możliwość dojazdu.
        </p>
      </>),
      slug: 'szybki-odbior'
    },
  ]
}

const Features = () => {

  const [activeFeature, setActiveFeature] = useState(null)

  const HandleDeactivateFeature = () => {
    setActiveFeature(null)
  }


  return (
    <>
      <Box id={`co-zyskasz`}>
        <h2>Sprawdź, co zyskasz!</h2>
        <p>
          Sprzedaż samochodu z MARI to zdecydowanie korzystniejsza opcja niż oddanie do komisu czy sprzedawanie
          samodzielnie.
        </p>

        <FeaturesList
          features={features.profits}
          activeFeature={activeFeature}
          activate={(slug) => setActiveFeature(slug)}
          deactivate={HandleDeactivateFeature}
          id={'co-z-nami-zyskasz'}
        />
        <div className="features-interruption">
          <h3>Co wyróżnia nasz skup samochodów?</h3>
          <p>
            Przeanalizowaliśmy rynek samochodów używanych w trójmieście, dzięki czemu nakreśliliśmy kluczowe filary na
            których opieramy skup samochodów używanych.
          </p>
        </div>
        <FeaturesList
          features={features.fundations}
          activeFeature={activeFeature}
          activate={(slug) => setActiveFeature(slug)}
          deactivate={HandleDeactivateFeature}
          id={'co-nas-wyroznia'}
        />
      </Box>
      <div className="section" id={'gifts'}>
        <div className="page-content">
          <div className="gift" id={`gift-charger`}>
            <div className="gift-content">
              <div className="gift-title">
                Wielofunkcyjna ładowarka
              </div>
              <div className="gift-desc">
                <p>
                  Przy każdej transakcji otrzymasz od nas awaryjną ładowarkę wielofunkcyjną, która sprawdzi się zarówno
                  przy codziennym korzystaniu z samochodu, jak i w sytuacjach awaryjnych.
                </p>
                Urządzenie posiada:
                <ul>
                  <li>- wyjście USB 5v</li>
                  <li>- wbudowany powerbank 2200mAh</li>
                  <li>- latarkę białą</li>
                  <li>- latarkę ostrzegawczą (czerwoną)</li>
                  <li>- zbijak do szyb</li>
                  <li>- nóż do pasów</li>
                </ul>
              </div>
            </div>
            <div className="gift-img">
              <img src={ChargerImg} alt="wielofunkcyjna ładowarka awaryjna"/>
            </div>
          </div>
          <div className="gift" id={`gift-maxiecu`}>
            <div className="gift-content">
              <div className="gift-title">
                Interfejs diagnostyczny
              </div>
              <div className="gift-desc">
                <p>
                  Do każdego zakupionego samochodu o wartości przekraczającej 20 tyś. zł dołączamy interfejs
                  diagnostyczny MaxiEcu, wraz z licencją na jedna z pośród ponad 40 najpopularniejszych marek
                  samochodów.
                </p>
                <p>
                  Interfejsy MaxiEcu pozawalają zarówno na diagnostykę samochodu, odczytywanie, kasowanie ewentualnych
                  błędów jak i wprowadzanie zmian w systemach pojazdu.
                </p>
                <p>
                  Aby dowiedzieć się więcej na temat interfejsu MaxiEcu wejdź na stronę producenta: <a
                  href="https://maxiecu.com/pl/produkty/samochody-osobowe/" target={"_blank"}>ww.maxiecu.com</a>
                </p>

              </div>
            </div>
            <div className="gift-img">
              <img src={MaxiecuImg} alt="wielofunkcyjna ładowarka awaryjna"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
