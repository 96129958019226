import React, {useEffect, useState} from "react";
import {Routes, Route} from "react-router-dom";
import './styles/mari.scss';
import Header from "./components/header";
import Footer from "./components/footer";
import PhoneIcon from "./img/icons/phone.svg";
import HomePage from "./components/home-page";
import Terms from "./components/terms";


function Mari() {

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', HandleScroll)
    return (() => window.removeEventListener('scroll', HandleScroll))
  }, [])

  const HandleScroll = () => {
    setScrolled(window.scrollY > 250)
  }


  return (
    <>
      <Header scrolled={scrolled}/>
      <Routes>
        <Route path={"/"} element={<HomePage/>}/>
        <Route path={"/regulamin"} element={<Terms/>}/>
      </Routes>
      <Footer/>


      <div id="call-us" className={`${scrolled ? 'small' : 'expanded'}`}>
        <a href="tel:510430420">
          <div className="call-us-btn">
            <img src={PhoneIcon} alt="+48 510 430 420"/>
            <div className={'number'}>510 430 420</div>
          </div>
          <div className="call-us-bg"/>
        </a>
      </div>

      <div id="vertical-line"/>
    </>
  );
}

export default Mari;
