import React, {useContext} from "react";
import {FormContext} from "./sell-form";


const CheckField = ({name, label, options}) => {

  const {errors, register, getValues, setValue} = useContext(FormContext)


  return (
    <div className={`form-field check-field ${errors[name] ? 'with-error' : ''}`}>
      {(errors[name]) && (
        <div className={`form-field-error`}>{errors[name]?.message || 'wymagane'}</div>
      )}
      <input
        type="checkbox"
        id={name}
        {...register(name, options)}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  )
}

export default CheckField;