import React, {useEffect, useRef, useState} from 'react';
import {HashLink} from "react-router-hash-link";

const menuItems = [
  {
    name: 'Home',
    anchor: 'hero'
  },
  {
    name: 'Sprzedaj samochód',
    anchor: 'sprzedaj'
  },
  {
    name: 'Kup samochód',
    anchor: 'oferowane-samochody'
  },
  {
    name: 'Kontakt',
    anchor: 'kontakt'
  }
]


const types = {
  regular: 'regular',
  mobile: 'mobile'
}

const CalcMenuType = () => {
  const ww = window.innerWidth
  return ww > 768 ? types.regular : types.mobile
}


const Menu = ({scrolled}) => {

  const [menuType, setMenuType] = useState(CalcMenuType)
  const [openMenu, setOpenMenu] = useState(false)
  const menuContainer = useRef()

  // register event listener to window width
  useEffect(() => {
    window.addEventListener('resize', HandleMenuType)
    return (() => {
      window.removeEventListener('resize', HandleMenuType)
    })
  })

  // set menu type, based on window width
  const HandleMenuType = () => {
    const newType = CalcMenuType()
    if (newType === types.regular) setOpenMenu(false)
    setMenuType(newType)
  }

  // toggle menu status
  const HandleBurgerClick = () => {
    setOpenMenu(!openMenu)
  }

  // close mobile menu on mouse leave
  useEffect(() => {
    menuContainer?.current?.addEventListener('mouseleave', () => {
      if (openMenu) {
        setOpenMenu(false)
      }
    })
  }, [menuContainer])

  return (
    <div className={`menu-container`}>
      {(menuType === 'regular' && !scrolled) ? (
        <nav className={`menu`}>
          {menuItems.map(item => (
            <div className={`menu-item`} key={item.name}>
              <HashLink smooth to={`/#${item.anchor}`}>
                <a>{item.name}</a>
              </HashLink>
            </div>
          ))}
        </nav>
      ) : (
        <nav className={`menu burger-menu ${openMenu ? 'open-menu' : 'closed-menu'}`} ref={menuContainer}>
          <div className="menu-btn burger-btn" onClick={HandleBurgerClick}>
            <div className="line1"/>
            <div className="line2"/>
            <div className="line3"/>
          </div>
          <div className="menu-container">
            {menuItems.map(item => (
              <div className={`menu-item`} key={item.name}>
                <HashLink smooth to={`/#${item.anchor}`}>
                  <a onClick={() => setOpenMenu(false)}>{item.name}</a>
                </HashLink>
              </div>
            ))}
          </div>
        </nav>
      )}
    </div>
  );
};

export default Menu;
