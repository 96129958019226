import React, {createContext, useState} from 'react';

// steps icons
import zgloszenieIcon from '../img/icons/zgloszenie.png'
import wycenaIcon from '../img/icons/wycena.png'
import ogledzinyIcon from '../img/icons/ogledziny.png'
import dokumentyIcon from '../img/icons/dokumenty.png'
import SellForm from "./sell-form";

export const EmailSendContext = createContext()


const SellCar = () => {
  const [emailSend, setEmailSend] = useState(false)

  return (
    <EmailSendContext.Provider value={{emailSend: setEmailSend}}>
      <div className={`section`} id={`sprzedaj`}>
        <div className="page-content">
          <div className="steps">
            <div className="inner">
              <div className="inner-content">
                <h2>Jak przebiega sprzedaż?</h2>
                <p>
                  To jest tak proste, że nie trzeba się rozpisywać!
                </p>
                <div className="steps-list">
                  <Step
                    title={`Wypełnij formularz`}
                    desc={(<>
                      <p>
                        Aby uzyskać jak najbardziej precyzyjną wycenę, przekaż w formularzu jak najwięcej informacji o
                        samochodzie, oraz załącz zdjęcia z zewnątrz, środka oraz komory silnika.
                      </p>
                      <p>
                        Możesz również skontaktować sie z nami telefoniecznie <a href="tel:510430420">[+48
                        510-430-420]</a> lub droga mailową <a href="mailto:shop@mari.pl">[shop@mari.pl]</a>.
                      </p>
                    </>)}
                    icon={zgloszenieIcon}
                  />
                  <Step
                    title={`Wstępna wycena pojazdu`}
                    desc={`Na podstawie otrzymanych od Ciebie informacji przygotowujemy wstępną ofertę.`}
                    icon={wycenaIcon}
                  />
                  <Step
                    title={`Oględziny samochodu`}
                    desc={`Po zaakceptowaniu wstępnej wyceny umówimy się na bezpłatne sprawdzenie stanu Twojego samochodu.`}
                    icon={ogledzinyIcon}
                  />
                  <Step
                    title={`Sprzedaż`}
                    desc={`Po ustaleniu satysfakcjonującej dla Ciebie ceny pojazdu – podpisujemy umowę, płacimy, przekazujemy prezent i odbieramy auto.`}
                    icon={dokumentyIcon}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form" id={"formularz-wyceny"}>
            <div className="inner">
              <div className="inner-content">
                <div className="form-header">
                  <div className="form-title">Sprzedaj samochód</div>
                </div>
                <div className="form-container">
                  <p>Aby uzyskać jak najbardziej precyzyjną wycenę, przekaż w formularzu jak najwięcej informacji o
                    samochodzie, oraz załącz zdjęcia z zewnątrz, środka oraz komory silnika.</p>
                  <div className="form-content">
                    {emailSend ? (
                      <>
                        <h4>Dziękujemy.<br/>Otrzymaliśmy Twoje zgłoszenie i wkrótce się skontaktujemy</h4>
                      </>
                    ) : (
                      <SellForm/>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EmailSendContext.Provider>
  );
};


const Step = ({title, desc, icon}) => {
  return (
    <div className={'step'}>
      <div className="step-ico round-icon">
        <div className="img">
          <img src={icon} alt={title}/>
        </div>
      </div>
      <div className="step-content">
        <div className="step-title">{title}</div>
        <div className="step-desc">{desc}</div>
      </div>
    </div>
  )
}

export default SellCar;
