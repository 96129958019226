import React, {useEffect, useRef, useState} from 'react';

const Feature = ({feature, active, activate, deactivate, list}) => {

  const box = useRef()
  const desc = useRef()

  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (active) {
      calcBox()
    } else {
      box.current.style = null
      desc.current.style = null
      setIsActive(false)
    }
  }, [active])


  // register event listener to window width
  useEffect(() => {
    window.addEventListener('resize', isActive ? calcBox : {})
    return (() => {
      window.removeEventListener('resize', isActive ? calcBox : {})
    })
  })

  const calcBox = () => {
    const sizeRate = .3

    const listRect = list.current.getBoundingClientRect()
    const boxRect = box.current.getBoundingClientRect()
    const descRect = desc.current.getBoundingClientRect()
    const innerDescRect = desc.current.childNodes[0].getBoundingClientRect()

    box.current.style.right = `-${((listRect.x + listRect.width) - (boxRect.x + boxRect.width)) * sizeRate}px`
    box.current.style.left = `-${(boxRect.x - listRect.x) * sizeRate}px`
    box.current.style.top = `50%`
    box.current.style.bottom = `auto`
    box.current.style.transform = `translateY(-50%)`
    box.current.style.zIndex = '650'
  }

  const calcBoxDesc = () => {
    const innerDescRect = desc.current.childNodes[0].getBoundingClientRect()
    desc.current.style.height = `${innerDescRect.height}px`
  }

  const HandleBoxTransitionEnd = (e) => {
    if (active) {
      calcBoxDesc()
      setIsActive(true)
    }
  }

  const HandleClick = (e) => {
    if (!e?.cancelable) {return}
    if (active) {
      deactivate()
    } else {
      activate()
    }
  }

  const HandleDeactivate = () => {
    deactivate()
  }


  return (
    <div
      className={`single-feature ${active ? 'sf-active' : ''} ${isActive ? 'isActive' : ''}`}
      onMouseEnter={activate}
      onMouseLeave={HandleDeactivate}
      onTouchEnd={HandleClick}
    >
      <div className="single-feature-main" ref={box} onTransitionEnd={HandleBoxTransitionEnd}>
        <div className="feature-icon">
          <div className="img">
            <img src={`./img/icons/${feature.slug}.png`} alt=""/>
          </div>
        </div>
        <div className="feature-content">
          <div className="feature-title"><span>{feature.title}</span></div>
          <div className="close" onClick={HandleClick}>&#x2715;</div>
          <div className="feature-desc" ref={desc}>
            <div className="feature-desc-inner">
              {typeof feature.content === 'string' ? (
                <p>{feature.content}</p>
              ) : (
                <>{feature.content}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
