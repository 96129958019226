import React from 'react';
import {Link} from "react-router-dom";
import Logo from './../img/mari-logo.svg'
import Menu from "./menu";




const Header = ({scrolled}) => {

  return (
    <header id={`main-header`}>
      <div className="page-content">
        <div className="logo">
          <Link to={'/'}>
            <img src={Logo} alt="MARI"/>
          </Link>
        </div>
        <Menu scrolled={scrolled}/>
      </div>
    </header>
  );
};

export default Header;
