import React from 'react';
import Offer from "./offer-single";


const Offers = ({offers}) => {

  return (
      <div className={"offers"}>
          {offers.map(offer => (
            <Offer offer={offer} key={offer.id}/>
          ))}
      </div>
  );
};

export default Offers;
