import React, {useContext} from 'react';
import {NumberFormat} from "../functions";
import {OfferContext} from "./buy-car";



const Offer = ({offer}) => {

  const photos = offer.acf.photos

  const {setOffer} = useContext(OfferContext)

  const price = () => {
    return NumberFormat(offer.acf.price)
  }

  return (
    <div className={"offer"} onClick={() => setOffer(offer)}>
      <div className="offer-inner">
        <div className="offer-img">
          <div className="img-side first-side" style={{backgroundImage: `url(${photos[0].full_image_url})`}}/>
          <div className="img-side sec-side" style={{backgroundImage: `url(${photos[1].full_image_url})`}}/>
        </div>
        <div className="offer-data">
          <div className="offer-data-title">{offer.title.rendered}</div>
          <Row label={"rocznik"} value={offer.acf.year}/>
          <Row label={"silnik"} value={offer.acf.engine}/>
          <Row label={"przebieg"} value={offer.acf.mileage} number={true} suffix={`km`}/>
          <div className="offer-data-price number"><span>{price()}</span> zł</div>
        </div>
      </div>
    </div>
  );
};

export default Offer;



export const Row = ({label, value=null, number=false, className='', suffix=''}) => {
  if (!value) return ''
  if (number) {
    value = NumberFormat(value)
  }

  return (
    <div className={`offer-data-row ${className}`}>
      <div className="label">{label}:</div>
      <div className="value">{value} {suffix}</div>
    </div>
  )
}