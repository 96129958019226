import React from 'react';
import {HashLink} from "react-router-hash-link";

const Footer = () => {
  return (
    <div className={`footer`}>
      <div className="page-content">
        <div className="inner">
          <div className="contact" id={"kontakt"}>
            <h3>MARI</h3>
            shop@mari.pl
            510 430 420
          </div>
          <div className="company-data">
            Mari sp.z o.o., ul. Fleszarowej-Muskat 7, 81-589 Gdynia, NIP: 5862337148, REGON: 381470408, KRS: 0000751301
          </div>
          <div className="terms">

            <HashLink smooth to={'/regulamin#top'}>
              <a>Regulamin i polityka prywatności</a>
            </HashLink>
          </div>
        </div>
      </div>
      <div className="second-footer">
        <div className="page-content">
          &copy; Wszelkie prawa zastrzeżone
        </div>
      </div>
    </div>
  );
};

export default Footer;
