import React from 'react';
import Hero from "./hero";
import AboutUs from "./about-us";
import Features from "./features";
import SellCar from "./sell-car";
import BuyCar from "./buy-car";

const HomePage = () => {
  return (
    <div>
      <Hero/>
      <AboutUs/>
      <Features/>
      <SellCar/>
      <BuyCar/>
    </div>
  );
};

export default HomePage;
