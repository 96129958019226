import React from 'react';
import Box from "./box";

const AboutUs = () => {
  return (
    <Box id={'o-nas'}>
      <div className={`section`}>
        <div className="content">
          <p>
            W pracy kierujemy się podstawową zasadą – skupujemy tak, jakbyśmy to my sprzedawali, sprzedajemy tak,
            jakbyśmy to my kupowali! Może i brzmi zawile, ale mechanizm jest prosty – do każdego zlecenia podchodzimy
            indywidualnie, solidnie, dbając o zadowolenie naszego Klienta.
          </p>
          <p>
            My po prostu lubimy samochody. A wiedza naszych ekspertów to nie efekt oglądania YouTube :) – to lata w
            branży moto: diagnostyka, naprawy, lakiernictwo, handel a nawet projektowanie! Dzięki temu zapewniamy usługę
            z pełnym zaangażowaniem i na wysokim poziomie.
          </p>
        </div>
      </div>
    </Box>
  );
};

export default AboutUs;
