import React from 'react';

const Box = ({children, shadow=true, id}) => {
  return (
    <div className={`section box ${shadow ? 'with-shadow' : ''}`} id={id}>
      <div className="inner">
        <div className="inner-content">
          <div className="page-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Box;
