import React, {useEffect, useRef} from 'react';
import Feature from "./feature-single";

const FeaturesList = ({features, activeFeature, activate, deactivate, id}) => {

  const list = useRef()

  return (
    <div className="features-list" id={id}>
      <div className="feature-empty-space"/>
      <div className="feature-list-grid" ref={list}>
        {features.map(feature => (
          <Feature
            list={list}
            feature={feature}
            key={feature.title}
            active={feature.slug === activeFeature}
            activate={() => activate(feature.slug)}
            deactivate={deactivate}
          />
        ))}
      </div>
    </div>
  );
};

export default FeaturesList;
