import React, {createContext, useEffect, useRef, useState} from 'react';
import Box from "./box";

import WhiteBMW from './../img/white-bmw.png'
import Offers from "./offers";
import OfferDetails from "./offer-details";

export const OfferContext = createContext()


const BuyCar = () => {

  const [offers, setOffers] = useState('fetching')
  const [offer, setOffer] = useState()
  const fetchOffers = useRef(true)

  useEffect(() => {
    if (!fetchOffers.current) return
    fetch('https://maxiecu.com/wp-json/wp/v2/mari/cars')
      .then(response => response.json())
      .then(data => setOffers(data))
      .catch(err => {
        console.log(err)
      })
    fetchOffers.current = false

  }, [offers])


  return (
    <OfferContext.Provider value={{setOffer: setOffer}}>
      <Box id={`oferowane-samochody`}>
        <div className={`section ${offers.length > 0 ? 'has-offer' : 'none-offer'}`}>
          <div className="content">
            <h2>Chcęsz kupić samochód?</h2>
            {offers.length > 0 ? (
              <>
                <p>
                  Sprawdź co mamy w aktualnej ofercie sprzedaży. Jesteśmy pewni, że każdy z prezentowanych samochodów
                  ma potencjał sprawienia nowemu właścicielowi mnóstwo radości.
                </p>
                {offers === 'fetching' ? (
                  <div className="lds-ring">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                  </div>
                ) : (
                  <Offers offers={offers}/>
                )}
                <p>Jeśli z jakiś powodów w naszej flocie nie ma „tego jedynego” – skontaktuj się z nami. Spróbujemy
                  znaleźć coś dla Ciebie.</p>
              </>
            ) : (
              <div className={`alt-offers`}>
                <p className={"alt-title"}>Aktualnie nie posiadamy żadnych samochodów na sprzedaż.</p>
                <p>Skontaktuj się z nami, zrobimy wszystko co w naszej mocy, aby znaleźć samochód idealny dla
                  Ciebie.</p>
              </div>
            )}
          </div>
        </div>
        <div className="floating-car">
          <img src={WhiteBMW} alt=""/>
        </div>
      </Box>
      <OfferDetails offer={offer}/>
    </OfferContext.Provider>
  );
};

export default BuyCar;
