import React from 'react';
import Box from "./box";

const Terms = () => {
  return (
    <div className={"single-page"}>
      <Box>
        <div className="content">
          <h2>Regulamin</h2>
          <ol>
            <li>
              <h3>Firma, kontakt</h3>
              <ol>
                <li>
                  <p>Sklep internetowy działający w Internecie pod adresem www.maxiecu.eu/shop jest prowadzony przez
                    MaxiEcu Sp. z o.o. Sp. K., działająca pod adresem: 81-589 Gdynia Ul. Fleszarowej-Muskat 7; wpisana
                    do krajowego rejestru sądowego prowadzonego przez Sąd Rejonowy Gdańsk – Północ w Gdańsku Wydział VII
                    Gospodarczy KRS pod numerem KRS: 0000698844 (dalej: „MaxiEcu”), NIP: 5862323620; REGON:
                    368496355.</p>
                </li>
                <li>
                  <p>Klient może skontaktować się z podmiotem prowadzącym sklep internetowy pod adresem e-mail:
                    shop@maxiecu.com , telefonicznie: 58 676 02 67, przez formularz kontaktowy dostępny w serwisie lub
                    listownie na adres siedziby MaxiEcu.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Ogólne zasady korzystania ze sklepu internetowego MaxiEcu</h3>
              <ol>
                <li>
                  <p>Z sklepu internetowego mogą korzystać wyłącznie osoby, które ukończyły 18 lat.</p>
                </li>
                <li>
                  <p>Każdy, kto nie ukończył 18 lat, może korzystać ze sklepu internetowego wyłącznie za zgodą swoich
                    rodziców lub opiekunów prawnych.</p>
                </li>
                <li>
                  <p>Każdy, kto zarejestruje się i zrobi zakupy w sklepie internetowym, poda swoje dane osobowe i wyraża
                    zgodę na ich przetwarzanie przez MaxiEcu na warunkach określonych w&nbsp;Polityce prywatności.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Złożenie zamówienia</h3>
              <ol>
                <li>
                  <p>Zamówienia są przyjmowane przez www.maxiecu.eu/shop</p>
                </li>
                <li>
                  <p>Opis produktów w sklepie internetowym zawierają informacje o funkcjach produktów, ich cenach, oraz
                    dostępności, a jeśli dotyczy – dostępnym wariancie produktu.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Realizacja zakupu</h3>
              <ol>
                <li>
                  <p>Po wybraniu danego produktu, należy kliknąć ikonę „Dodaj do koszyka”.</p>
                </li>
                <li>
                  <p>Produkt jest przenoszony do koszyka. Kliknięcie ikony koszyka w prawym górnym rogu strony aktywuje
                    stronę wyświetlającą wszystkie produkty w koszyku.</p>
                </li>
                <li>
                  <p>Wybranie opcji „Zamówienie” spowoduje uruchomienie strony, na której Klient powinien wprowadzić
                    dane wymagane do transakcji i wysyłki zamówionych produktów.</p>
                </li>
                <li>
                  <p>Po wybraniu metody wysyłki i wpisaniu wszystkich wymaganych danych (w tym, w razie potrzeby, danych
                    rozliczeniowych), należy wybrać metodę płatności.</p>
                </li>
                <li>
                  <p>Klient może wybrać płatność przelewem online przez Paynow, PayPal, PayU, płatność przelewem
                    tradycyjnym na konto bankowe, płatność kartą płatniczą Visa, Visa Electron, Mastercard,
                    MasterCard,&nbsp;Electronic, Maestro&nbsp;lub, jeśli jest dostępna – płatność przy
                    odbiorze.&nbsp; Podmiotem świadczącym obsługę płatności online w zakresie płatności kartami jest
                    Blue Media S.A.</p>
                </li>
                <li>
                  <p>Klient składa zamówienie, klikając przycisk „Potwierdź zamówienie”</p>
                </li>
                <li>
                  <p>Po kliknięciu przycisku „Potwierdź zamówienie”, a jeśli Klient wybrał płatność online - po
                    dokonaniu płatności za pośrednictwem Paynow, PayPal lub PayU, Klient zostaje przekierowany na stronę
                    potwierdzającą zamówienie; z tej strony mogą kontynuować zakupy lub przejść do podsumowania
                    zamówienia</p>
                </li>
                <li>
                  <p>Jeśli jako metodę płatności wybrano płatność online, płatność musi zostać dokonana natychmiast po
                    złożeniu zamówienia. Jeśli MaxiEcu lub podmioty uczestniczące w transakcji nie otrzymają płatności,
                    zamówienie uważa się za złożone nieprawidłowo, co oznacza, że ​​nie ma umowy, a zamówienie nie
                    zostanie przetworzone. W takim przypadku Klient będzie musiał złożyć kolejne zamówienie.</p>
                </li>
                <li>
                  <p>Na każdym etapie składania zamówienia, do momentu dokonania płatności, Klient może anulować
                    zamówienie, przerywając proces zamawiania i opuszczając stronę zamawiania. Zamówienie, którego
                    umieszczenie nie zostało zakończone, nie zostanie przetworzone.</p>
                </li>
                <li>
                  <p>Niezwłocznie po złożeniu zamówienia klient otrzyma wiadomość e-mail z potwierdzeniem zamówienia na
                    adres podany w danych logowania. Wiadomość e-mail będzie zawierać indywidualny numer zamówienia,
                    który można wykorzystać np. Do sprawdzenia statusu zamówienia.</p>
                </li>
                <li>
                  <p>MaxiEcu może skontaktować się z Klientem na adres e-mail lub wskazany numer telefonu, aby wyjaśnić
                    wszelkie wątpliwości, potwierdzić złożenie zamówienia lub w innych sprawach związanych z realizacją
                    zamówienia.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Ceny</h3>
              <ol>
                <li>
                  <p>Ceny w sklepie internetowym są cenami brutto, w tym VAT.</p>
                </li>
                <li>
                  <p>Cena produktu jest zwiększona o koszt wysyłki, który zależy od wybranej metody wysyłki i płatności,
                    chyba, że dany produkt objęty jest promocją darmowej wysyłki.</p>
                </li>
                <li>
                  <p>Cena określona na stronie w momencie składania zamówienia jest ceną ostateczną, wiążącą zarówno dla
                    Klienta, jak i dla MaxiEcu. Po złożeniu zamówienia cena nie ulegnie zmianie, niezależnie od
                    wszelkich korekt cen dokonanych przez MaxiEcu i niezależnie od jakichkolwiek ofert specjalnych.</p>
                </li>
                <li>
                  <p>MaxiEcu zastrzega sobie prawo do zmiany cen produktów dostępnych w sklepie internetowym,
                    wprowadzania nowych produktów, uruchamiania i odwoływania ofert specjalnych na stronach
                    internetowych sklepu lub zmiany ich zgodnie z Kodeksem Cywilnym i innymi obowiązującymi
                    przepisami.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Dostępność zamówionych produktów</h3>
              <ol>
                <li>
                  <p>Wszystkie produkty oferowane w sklepie internetowym są dostępne w magazynie.</p>
                </li>
                <li>
                  <p>W szczególnych przypadkach, np. Jeśli wielu klientów zamawia ten sam produkt jednocześnie, produkt
                    może nie być dostępny. W takim przypadku Klient zostanie poinformowany, że zamówienie nie może
                    zostać zrealizowane.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Trudności w przetworzeniu zamówienia</h3>
              <ol>
                <li>
                  <p>Jeśli produkt jest niedostępny lub zamówienie nie może zostać przetworzone z jakiegokolwiek innego
                    powodu, Klient zostanie powiadomiony e-mailem lub telefonicznie o wszelkich problemach z
                    przetwarzaniem zamówień i możliwych rozwiązaniach.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Sposób wysyłki i czas realizacji zamówienia</h3>
              <ol>
                <li>
                  <p>Towary są wysyłane na terenie Europy. Zamówienia spoza tego obszaru wymagają dodatkowego kontaktu w
                    celu ustalenia warunków wysyłki.</p>
                </li>
                <li>
                  <p>W przypadku wybrania sposobu płatności „za pobraniem / płatność przy odbiorze”, zamówienie zostaje
                    przekazane do realizacji bezpośrednio po jego złożeniu. W przypadku wyboru jednego ze sposobów
                    płatności online, lub przelewu bankowego zamówienie zostaje przekazane do realizacji po
                    potwierdzeniu wpływu należności na konto MaxiEcu w wysokości 100% należnej kwoty za zamówienie. Czas
                    realizacji zamówienia jest liczony od momentu uzyskania pozytywnej autoryzacji płatności.</p>
                </li>
                <li>
                  <p>Zamówienia są dostarczane za pośrednictwem firmy kurierskiej wybranej przez Klienta.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Formy płatności za zamówienie</h3>
              <ol>
                <li>
                  <p>Za pobraniem: zapłata następuje do rąk kuriera przy odbiorze przez Klienta przesyłki
                    kurierskiej.</p>
                </li>
                <li>
                  <p>Przelew online: przelewy, które akceptowane są przez MaxiEcu, to tzw. szybkie przelewy dokonane
                    przy użyciu serwisu Paynow,&nbsp;PayU lub&nbsp; PayPal. Cechą szybkich przelewów jest to, że po
                    wybraniu tej formy płatności Klient zostaje w sposób bezpieczny przekierowany na stronę operatora
                    płatności a następnie, ze strony operatora na stronę wybranego z listy banku, w celu dokonania
                    transferu pieniędzy. Operacja płatnicza dokonywana jest pomiędzy klientem a wybranym operatorem
                    płatności.</p>
                </li>
                <li>
                  <p>Płatność przelewem bankowym - Klient otrzyma numer konta w wiadomości potwierdzającej przyjęcie
                    zamówienia. Numer Konta jest również podany na stronie sklepu w zakładce „Informacje o
                    płatnościach.</p>
                </li>
                <li>
                  <p>Uwaga! Sklep Internetowy nigdy nie wyśle do Klienta wiadomości z informacją, iż zmieniło się konto
                    do wpłaty, wraz z prośbą o dokonanie przelewu tradycyjnego. Jeśli Klient otrzyma takiego maila – nie
                    należy dokonywać wpłaty, czy też odpowiadać na niego, należy przesłać niezmieniony e-mail do MaxiEcu
                    na adres: shop@maxiecu.com.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Umowa sprzedaży</h3>
              <ol>
                <li>
                  <p>Informacje umieszczone na stronie internetowej sklepu www.maxiecu.eu/shop nie stanowią oferty
                    zawarcia umowy w myśl przepisów Kodeksu Cywilnego.</p>
                </li>
                <li>
                  <p>Umowa jest zawierana w języku polskim.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Wykonanie umowy – termin</h3>
              <ol>
                <li>
                  <p>Czas realizacji zamówienia jest liczony od momentu uzyskania pozytywnej autoryzacji płatności, a w
                    przypadku zamówień z opcją płatności "za pobraniem" - od momentu otrzymania zamówienia z kompletnymi
                    danymi umożliwiającymi jego realizacje.</p>
                </li>
                <li>
                  <p>Dostawa na terenie Polski - w zależności od rodzaju wybranej przez Klienta opcji dostawy MaxiEcu
                    zobowiązuje się do przekazania towaru Klientowi w terminie do 3 dni roboczych od poprawnego złożenia
                    zamówienia. Dostawa do pozostałych krajów w Europie - w zależności od kraju docelowego czas
                    realizacji może wynosić od 7 dni roboczych od poprawnego złożenia zamówienia. W przypadku braku
                    towaru lub niemożliwości zrealizowania zamówienia z innej przyczyny, MaxiEcu niezwłocznie
                    skontaktuje się z Klientem proponując możliwe w danej sytuacji rozwiązania. Dni robocze liczone są
                    od poniedziałku do piątku , z wyłączeniem dni ustawowo wolnych od pracy.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Koszt dostawy</h3>
              <ol>
                <li>
                  <p>Koszt dostawy uzależniony jest od wybranego sposobu dostawy i płatności. Obowiązujący cennik jest
                    podany w zakładce <a className="red" href="../delivery/" target="_blank" rel="noopener">„Informacje
                      o wysyłce”</a>.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Odstąpienie od umowy</h3>
              <ol>
                <li>
                  <p>Klient ma prawo odstąpić od umowy sprzedaży towaru w terminie 14 dni, bez podania jakiejkolwiek
                    przyczyny, z wykluczeniem produktów cyfrowych, co do których została wyrażona zgoda na wykonanie
                    umowy w całości i dostarczenie treści cyfrowej (pobranie) przed upływem terminu do odstąpienia od
                    umowy.</p>
                </li>
                <li>
                  <p>Prawo do odstąpienia od umowy wygasa po upływie 14 dni od dnia w którym Klient wszedł w posiadanie
                    rzeczy lub w którym osoba trzecia inna niż przewoźnik i wskazana przez Klienta weszła w posiadanie
                    rzeczy.</p>
                </li>
                <li>
                  <p>Aby skorzystać z prawa odstąpienia od umowy, Klient musi złożyć MaxiEcu jednoznaczne oświadczenie o
                    odstąpieniu od umowy w jeden ze wskazanych sposobów:</p>
                  <p>- pocztą na adres: MaxiEcu Sp. z o.o. Sp. k, ul. Fleszarowej-Muskat 7, 81-589 Gdynia,</p>
                  <p>- przez wysłanie wiadomości e-mail na adres: shop@maxiecu.com,</p>
                  <p>- przez wypełnienie i wysłanie formularza on-line, dostępnego na stronie sklepu „Formularz
                    reklamacji / zwrotu”.</p>
                  <p>Uwaga! Każdy ze sposobów przesłania oświadczenia jest dopuszczalny i wszystkie są równorzędne pod
                    względem skuteczności. MaxiEcu zaleca jedynie korzystanie z formularza on-line ze względu na łatwość
                    i szybkość złożenia oświadczenia.</p>
                </li>
                <li>
                  <p>Do zachowania terminu do odstąpienia od umowy, wystarczy, aby Klient wysłał informację dotyczącą
                    wykonania przysługującego Klientowi prawa odstąpienia od umowy przed upływem terminu do odstąpienia
                    od umowy.</p>
                </li>
                <li>
                  <p>W przypadku odstąpienia od umowy sprzedaży, MaxiEcu zwraca Klientowi wszystkie otrzymane płatności,
                    w tym koszty dostarczenia rzeczy przy zakupie (z wyjątkiem dodatkowych kosztów wynikających z
                    wybranego przez Klienta sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia
                    oferowany przez MaxiEcu, niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym
                    MaxiEcu zostało poinformowane o decyzji Klienta o wykonaniu prawa odstąpienia od umowy
                    sprzedaży.</p>
                </li>
                <li>
                  <p>Zwrot płatności zostanie dokonany przy użyciu takich samych sposobów płatności, jakie zostały przez
                    Klienta użyte w pierwotnej transakcji, chyba że Klient wyraźnie zgodził się na inny sposób zwrotu,
                    który nie wiąże się dla niego z żadnymi kosztami.</p>
                </li>
                <li>
                  <p>MaxiEcu może wstrzymać się ze zwrotem płatności do czasu otrzymania rzeczy lub do czasu
                    dostarczenia dowodu jej odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.</p>
                </li>
                <li>
                  <p>Rzeczy należy odesłać na adres: MaxiEcu Sp. z o.o. Sp. k, ul. Fleszarowej-Muskat 7, 81-589 Gdynia,
                    niezwłocznie, a w każdym razie nie później niż 14 dni od dnia, w którym Klient poinformował MaxiEcu
                    o odstąpieniu od umowy sprzedaży. Termin jest zachowany, jeżeli Klient odeśle rzecz przed upływem
                    terminu 14 dni.</p>
                </li>
                <li>
                  <p>Zwrot rzeczy jest na koszt Klienta.</p>
                </li>
                <li>
                  <p>Klient odpowiada za zmniejszenie wartości rzeczy wynikające z korzystania z niej w sposób inny niż
                    było to konieczne do stwierdzenia charakteru, cech i funkcjonowania rzeczy.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Reklamacje</h3>
              <ol>
                <li>
                  <p>Przedmiotem sprzedaży są produkty nowe, bez wad. W przypadku wystąpienia wad produktów MaxiEcu
                    ponosi odpowiedzialność za wady.</p>
                </li>
                <li>
                  <p>Każda rzecz kupiona w sklepie internetowym może być reklamowana, zgodnie z obowiązującymi
                    przepisami kodeksu cywilnego dotyczącymi rękojmi, jeżeli w ciągu dwóch lat od jej wydania Klientowi,
                    stwierdzona zostanie wada stanowiąca o jej niezgodności z umową.</p>
                </li>
                <li>
                  <p>Reklamację można złożyć: drogą pocztową lub przez wypełnienie i wysłanie formularza on-line,
                    dostępnego na stronie sklepu „Formularz reklamacji / zwrotu”.</p>
                </li>
                <li>
                  <p>W przypadku reklamacji drogą pocztową, należy wskazać przyczyny reklamacji oraz informacje
                    niezbędne do zidentyfikowania zamówienia. Adres wysyłki: MaxiEcu Sp. z o.o. Sp. k, ul.
                    Fleszarowej-Muskat 7, 81-589 Gdynia,</p>
                </li>
                <li>
                  <p>MaxiEcu rozpatrzy reklamację w terminie 14 dni i poinformuje Klienta o sposobie jej
                    załatwienia.</p>
                </li>
                <li>
                  <p>W przypadku pozytywnego rozpatrzenia reklamacji złożonej drogą pocztową, Klient otrzyma zwrot
                    poniesionych, celowych kosztów wysłania wadliwego towaru.</p>
                </li>
                <li>
                  <p>W przypadku nie uwzględnienia reklamacji, towar zostanie odesłany Klientowi wraz z informacją o
                    niezasadności reklamacji.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Rezygnacja z zamówienia</h3>
              <ol>
                <li>
                  <p>Klient może zrezygnować z zamówienia.</p>
                </li>
                <li>
                  <p>Klient ma możliwość anulowania zamówienia poprzez kontakt z Działem Sprzedaży – przez adres e-mail:
                    shop@maxiecu.com lub poprzez formularz kontaktowy dostępny w serwisie.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Postanowienia końcowe</h3>
              <ol>
                <li>
                  <p>Do każdego zakupionego towaru dołączony jest dowód zakupu. Klient ma możliwość uzyskania także
                    faktury VAT. MaxiEcu nie ponosi odpowiedzialności za podanie błędnych danych przez Klienta.</p>
                </li>
                <li>
                  <h3>Przetwarzanie danych osobowych</h3>
                  <p>Wyrażenie przez Klienta zgody na przetwarzanie przez MaxiEcu danych osobowych jest dobrowolne,
                    jednakże brak zgody na przetwarzanie danych osobowych, uniemożliwia zarejestrowanie się w sklepie
                    internetowym i dokonywanie w nim zakupów. Wszelkie informacje w zakresie przetwarzania danych
                    osobowych znajdują się w Polityce prywatności dostępnej na stronie serwisu.</p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Box>
    </div>
  );
};

export default Terms;
