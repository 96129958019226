import React, {useContext, useState} from 'react';
import {FormContext} from "./sell-form";


const TextField = ({name, label, options}) => {

  const [focused, setFocused] = useState(false)
  const [customError, setCustomError] = useState(null)

  // get form errors
  const {errors, register, getValues, setValue} = useContext(FormContext)

  // add custom onBlur to field
  options = {
    ...options,
    onBlur: () => {
      setFocused(false)
    },
    onChange: (e) => {
      e.preventDefault()
      HandleNewValue(e)
    }
  }

  const HandleNewValue = (event) => {
    // get entered data
    let newValue = event.target.value


    // apply pattern for field
    if (options.pattern && options.onlyPattern) {
      newValue = newValue.match(options.pattern)[0]
    }

    if (options.maxLength) {
      newValue = newValue.slice(0, options.maxLength)
    }

    // check if match pattern or set error
    setCustomError(!newValue.match(options.pattern) && options.patternError ? options.patternError : null)

    // update value
    setValue(name, newValue, { shouldValidate: true })
  }

  return (
    <div className={`form-field text-field ${(getValues(name)?.length > 0 || focused) ? 'none-empty' : ''} ${errors[name] ? 'with-error' : ''}`}>
      {(errors[name] || customError) && (
        <div className={`form-field-error`}>{errors[name]?.message || customError || 'wymagane'}</div>
      )}
      <label htmlFor={name} className={`form-field-label`}>{label}</label>
      <input
        id={name}
        className={`form-field-input`}
        onChange={HandleNewValue}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        {...register(name, options)}
      />
    </div>
  );
}

export default TextField;


