import React from 'react';
import OfferDetailsInner from "./offer-details-inner";


const OfferDetails = ({offer}) => {

  return (
    <div className={`offer-details offer ${offer ? 'display' : 'hidden'}`}>
      <div className="offer-details-box">
        {offer && (<OfferDetailsInner offer={offer}/>)}
      </div>
    </div>
  );
};

export default OfferDetails;
