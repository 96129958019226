import React, {createRef, useContext, useEffect, useRef, useState} from 'react';

import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Keyboard, Navigation, Pagination, Thumbs} from "swiper";
import {Row} from "./offer-single";
import parse from "html-react-parser";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import {OfferContext} from "./buy-car";
import {NumberFormat} from "../functions";

const detailsContainer = createRef()

const OfferDetailsInner = ({offer}) => {

  const {setOffer} = useContext(OfferContext)

  const [photos, setPhotos] = useState()
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [thumbsNumber, setThumbsNumber] = useState()

  //// calculate thumbnails count
  // add listener to window resize
  useEffect(() => {
    window.addEventListener('resize', HandleWindowResize)
    // remove listener on component unmount
    return () => {
      window.removeEventListener('resize', HandleWindowResize)
    }
  }, [])

  const HandleWindowResize = () => {
    const newThumbsCount = CalcThumbsNumber()
    if (newThumbsCount !== thumbsNumber) {
      setThumbsNumber(newThumbsCount)
    }
    return true
  }
  const CalcThumbsNumber = () => {
    const contWidth = detailsContainer.current.getBoundingClientRect().width
    const calculatedNumber = parseInt(contWidth / 90)
    return (calculatedNumber)
  }

  useEffect(() => {
    if (!offer) return false
    const photoArray = offer.acf.photos.map(photo => ({
      original: photo.full_image_url,
      thumbnail: photo.medium_srcset.split(',')[0].split(' ')[0]
    }))
    setPhotos(photoArray)
    setThumbsNumber(CalcThumbsNumber())
  }, [offer])


  // closing modal
  const HandleClose = () => {
    setThumbsSwiper(null)
    setPhotos(null)
    setOffer(null)
  }
  document.addEventListener('keydown', e => {
    e.code === 'Escape' && HandleClose()
  })

  return (
    <div className="offer-details-inner" ref={detailsContainer}>
      <div className="close" onClick={HandleClose}>&#x2715;</div>

      <div className="offer-details-content">
        <div className="od-photos">
          {photos && (
            <>
              <Swiper
                pagination={{type: "fraction"}}
                navigation={true}
                modules={[Keyboard, FreeMode, Pagination, Navigation, Thumbs]}
                thumbs={{swiper: thumbsSwiper}}
                loop={true}
                className={"photos"}
                keyboard={{
                  enabled: true,
                }}
              >
                {photos.map((photo, i) => (
                  <SwiperSlide key={i}>
                    <img src={photo.original} alt=""/>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* thumbnails film roll */}
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                watchSlidesProgress={true}
                freeMode={true}
                slidesPerView={thumbsNumber}
                spaceBetween={4}
                className={"photos-roll"}
              >
                {photos.map((photo, i) => (
                  <SwiperSlide key={i}>
                    <img src={photo.thumbnail} alt=""/>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          )}

        </div>


        <div className="od-details">
          <div className="offer-data-title">{offer.title.rendered}</div>
          <Row label={"rocznik"} value={offer.acf.year}/>
          <Row label={"silnik"} value={offer.acf.engine}/>
          <Row label={"przebieg"} value={offer.acf.mileage} number={true} suffix={`km`}/>
          <Row label={"nadwozie"} value={offer.acf.chassis}/>
          <div className="offer-data-price number"><span>{NumberFormat(offer.acf.price)}</span> zł</div>
        </div>

        <div className="od-description">
          {parse(offer.content.rendered)}
        </div>
      </div>
    </div>
  );
};

export default OfferDetailsInner;
