import React, {createContext, useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";

import TextField from './form-field-text'
import CheckField from "./form-field-check";
import {useDropzone} from "react-dropzone";
import {EmailSendContext} from "./sell-car";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const FormContext = createContext({})


const SellForm = () => {
  const { register, handleSubmit, getValues, setValue, setError, formState: {errors} } = useForm({
    reValidateMode: 'onChange'
  })

  const {emailSend} = useContext(EmailSendContext)
  const [sending, setSending] = useState(false)
  const [emailError, setEmailError] = useState(null)


  const onSubmit = async (data) => {
    setEmailError(null)
    setSending(true)

    window.location.hash = 'formularz-wyceny'

    let formData = new FormData()
    files.map(file => {
      formData.append('file', file)
    })
    formData.append('str', JSON.stringify(data))

    const emailStatus = await fetch('https://mari.pl/send-mail', {
      method: 'POST',
      body: formData
    }).then(resp => resp.json())
      .then(data => {
        if (data.status === 0) {
          setEmailError('Nie udało się wysłać wiadomości, spóbuj ponownie za chwilę')
          console.error(data.error)
        }
        if (data.status === 1) {
          emailSend(true)
        }
      })

  }

  // dropzone config
  const [files, setFiles] = useState([])
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const thumbs = files.map(file => (
    <div className={`photo-preview`} key={file.name}>
      <div className="photo-preview-inner">
        <img src={file.preview} alt={file.name}/>
      </div>
    </div>
  ))


  return (
    <div className={`form-fields`}>
      <FormContext.Provider value={{errors: errors, setError: setError, getValues: getValues, setValue: setValue, register: register}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-set">
          <TextField
            name={`name`}
            label={`Imię`}
            options={{
              required: true,
            }}
          />
          <TextField
            name={`email`}
            label={`Adres email`}
            options={{
              required: true,
              pattern: emailRegex,
              // patternError: `podaj prawidłowy adres email`
            }}
          />
          <TextField
            name={`phone`}
            label={`Nr telefonu`}
            options={{
              required: true,
              maxLength: 9,
              minLength: 9,
              pattern: /^[0-9]*/,
              onlyPattern: true,
              // patternError: `podaj prawidłowy nr telefonu`
            }}
          />
        </div>
        <div className="form-set">
          <TextField
            name={`brand`}
            label={`Marka`}
            options={{
              required: true
            }}
          />
          <TextField
            name={`model`}
            label={`Model`}
            options={{
              required: true
            }}
          />
          <TextField
            name={`year`}
            label={`Rocznik`}
            options={{
              required: true,
              maxLength: 4,
              minLength: 4,
              pattern: /^[0-9]*/,
              onlyPattern: true,
            }}
          />
          <TextField
            name={`mileage`}
            label={`Przebieg [km]`}
            options={{
              required: true,
              maxLength: 7,
              pattern: /^[0-9]*/,
              onlyPattern: true,
            }}
          />
          <TextField
            name={`fuelType`}
            label={`Rodzaj paliwa`}
            options={{
              required: true
            }}
          />
          <TextField
            name={`engine`}
            label={(<>Pojemność silnika [cm<sup>3</sup>]</>)}
            options={{
              required: true,
              maxLength: 5,
              pattern: /^[0-9]*/,
              onlyPattern: true,
            }}
          />
        </div>
        <div className="form-set-title">
          Informacje dodatkowe <span>(opcjonalne)</span>
        </div>
        <div className="form-set">
          <TextField
            name={`color`}
            label={`Kolor`}
            options={{}}
          />
          <TextField
            name={`price`}
            label={`Proponowana cena [zł]`}
            options={{
              maxLength: 7,
              pattern: /^[0-9]*/,
              onlyPattern: true,
            }}
          />
          <TextField
            name={`owner`}
            label={`Którym jesteś właścicielem?`}
            options={{
              maxLength: 2,
              pattern: /^[0-9]*/,
              onlyPattern: true,
            }}
          />
          <TextField
            name={`date`}
            label={`Preferowany termin spotkania`}
            options={{}}
          />
          <TextField
            name={`city`}
            label={'Miejscowość'}
            options={{}}
          />
        </div>
        <div className="form-set extra-fields">
          <CheckField
            name={`crashed`}
            label={`Samochód powypadkowy`}
            options={{}}
          />
          <CheckField
            name={`demaged`}
            label={`Samochód uszkodzony`}
            options={{}}
          />
          <CheckField
            name={`notRegistered`}
            label={`Nie zarejestrowany w polsce`}
            options={{}}
          />
          <CheckField
            name={`withoutInsurance`}
            label={`Brak OC / przeglądu`}
            options={{}}
          />
          <CheckField
            name={`righthanded`}
            label={`Kierownica po prawej stronie`}
            options={{}}
          />
          <CheckField
            name={`fromPoland`}
            label={`Pochodzi z polskiego salonu`}
            options={{}}
          />
        </div>
        <div className="form-set single-column" id={`form-photos`}>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <div className="text">
              <p className="title">Dodaj zdjęcia</p>
              <p className="desc">możesz dodać do 5 zdjęć swojego samochodu,
                pokaż nam jak się prezentuje z zewnątrz, w środku oraz pod maską</p>
            </div>
            <aside className={`photos`}>
              {thumbs}
            </aside>
          </div>
        </div>
        <div className="form-set single-column" id={'form-terms'}>
          <CheckField
            name={'termsAccept'}
            label={(<>Zapoznałem się i akceptuję <a href={`/regulamin`} target={"_blank"}>regulamin oraz polityke
              prywatności</a></>)}
            options={{
              required: true
            }}
          />
        </div>

        <div className="form-footer">
          <button type="submit" className={`btn btn-round btn-light`} disabled={sending}>{sending ? 'Trwa wysyłanie...' : 'Wyślij'}</button>
        </div>
      </form>
      </FormContext.Provider>
    </div>
  );
}

export default SellForm;
